import styled from 'styled-components';

export const Container = styled.div`
  .colored {
    /* background: var(--text-color); */
  }
`;

export const Spacer = styled.div`
  height: 15vh;
  background: #e9e9e9;
`;
